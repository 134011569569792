import React from 'react'

const FirstIndex =  () => {

    return(
        <div className='firstIndex'>
            <div className='back1Index'></div>
            <img src="../procesador.png" alt="Javier villanueva  villaneuva ingeniería IT colombia bogota sistemas"/>
            <h1>VILLANUEVA <br/> INGENIERÍA</h1>
            <p>Soluciones empresariales de tecnología y comunicación.</p>
        </div>
    )

}

export default FirstIndex