import React, {useState} from "react"
import { Drawer } from 'antd';


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'

const Header = ({Ancla}) => {
    
  const [openDrawer, setOpenDrawer] = useState(false)

  const onClose = () =>{
    setOpenDrawer(false)
  }

  return (
  <>
  <a target='_blank' href="https://api.whatsapp.com/send?phone=573005565876"><img id='whatsFlotante' src="../whatsappLogo.png" alt="whatsapp villanueva ingenieria it consultoria bogota colombia"/></a> 
  <header>
    <Ancla to="index" spy={true} smooth={true} duration={500}>
      <img src="../logo2.png" alt="logo villanueva ingenieria "/>
    </Ancla>
    <ul>
      <Ancla to="index" spy={true} smooth={true} duration={500}>
        <li>Inicio</li>
      </Ancla>
      <Ancla to="about" spy={true} smooth={true} duration={500}>
        <li>¿Quiénes somos?</li>
      </Ancla>
      <Ancla to="servicios" spy={true} smooth={true} duration={500}>
        <li>Servicios</li>
      </Ancla>
      <Ancla to="why" spy={true} smooth={true} duration={500}>
        <li>¿Porqué elegirnos?</li>
      </Ancla>
      <Ancla to="proveedor" spy={true} smooth={true} duration={500}>
        <li>Distribución</li>
      </Ancla>
      <Ancla to="contact" spy={true} smooth={true} duration={500}>
        <li>Contacto</li>
      </Ancla>
    </ul>
    <div onClick={()=>setOpenDrawer(true)} className='hmgMenu'>
      <FontAwesomeIcon icon={faBars}/>
    </div>
    <Drawer placement='right' visible={openDrawer} onClose={onClose}>
      <ul className='menuDrawer'>
        <Ancla to="index" spy={true} smooth={true} duration={500}>
          <li onClick={()=>setOpenDrawer(false)}>Inicio</li>
        </Ancla>
        <Ancla to="about" spy={true} smooth={true} duration={500}>
          <li onClick={()=>setOpenDrawer(false)}>¿Quiénes somos?</li>
        </Ancla>
        <Ancla to="servicios" spy={true} smooth={true} duration={500}>
          <li onClick={()=>setOpenDrawer(false)}>Servicios</li>
        </Ancla>
        <Ancla to="why" spy={true} smooth={true} duration={500}>
          <li onClick={()=>setOpenDrawer(false)}>¿Porqué elegirnos?</li>
        </Ancla>
        <Ancla to="proveedor" spy={true} smooth={true} duration={500}>
          <li onClick={()=>setOpenDrawer(false)}>Distribución</li>
        </Ancla>
        <Ancla to="contact" spy={true} smooth={true} duration={500}>
          <li onClick={()=>setOpenDrawer(false)}>Contacto</li>
        </Ancla>
      </ul>
    </Drawer>
  </header>
  </>
)}

export default Header
