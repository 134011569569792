import React from 'react'

//useForm
import { useForm } from "react-hook-form";

//fontawasome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faMobileAlt} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
//antd
import {message} from 'antd'
//axios
import axios from 'axios'

const SixthIndex =  () => {

    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = (data) =>{
        console.log(data)
        axios.post('http://contact.villanuevaingenieria.com/sendemail', {
            name: data.name,
            email : data.email,
            message: data.message
        },{
            headers: {'Content-Type': 'application/json'}
        }).then(res=>{
            if (res.status === 200) {
                message.success("Gracias por contactar con nosotros")
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    return(
        <div className='sixthIndex'>
            <h1>PONTE EN CONTACTO</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input placeholder="Nombre" name="name" type="text" ref={register({ required: true })}/>
                <input placeholder="Correo Electrónico" name="email" type="text" ref={register({required: "Required",pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message: "invalid email address"}})}/>
                <textarea placeholder="Escribe tu mensaje" name="message" name="message" id="" cols="30" rows="10" ref={register({ required: true })}></textarea>
                <button type="submit">Enviar</button>
            </form>
            <div className='secondForm'>
                <span className='emailContact'> <FontAwesomeIcon icon={faEnvelope}/>soporte@villanuevaingenieria.com</span>
                <span className='mobilContact'> <FontAwesomeIcon icon={faMobileAlt}/>+57 300 5565876</span>
                <a id='face' target="_blank" href="https://www.facebook.com/villanuevaingenieria/"> <FontAwesomeIcon icon={faFacebook}/></a>
                <a id='insta' target="_blank" href="https://www.instagram.com/ingenieriavillanueva/?hl=es-la"> <FontAwesomeIcon icon={faInstagram}/></a>
                <a id='whats'target="_blank" href="https://api.whatsapp.com/send?phone=573005565876"> <FontAwesomeIcon icon={faWhatsapp}/></a>
                <img src="../payu.png" alt="payu javier villanueva ingenieria"/>
            </div>
            <span>Todos los derechos reservados 2020 - Powered By <a target="_blank" href="https://aidea.com.co">AIDEA S.A.S.</a></span>
        </div>
    )

}

export default SixthIndex