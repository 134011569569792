import React from 'react'

const FourthIndex =  () => {

    return(
        <div className='fourthIndex'>
            <h1>¿PORQUÉ ELEGIRNOS?</h1>
            <div className='targetWhy'>
                <div>
                    <h1>Soporte inmediato</h1>
                    <div className='circle'>
                        <img style={{objectFit: "cover"}} src="../soporte.svg" alt="soporte inemediato villaneuva ingenieria"/>
                    </div>
                </div>
                <div>
                    <h1>Experiencia</h1>
                    <div className='circle'>
                        <img src="../experiencia.svg" alt="experiencia villaneuva ingenieria"/>
                    </div>
                </div>
                <div>
                    <h1>Seguridad</h1>
                    <div className='circle'>
                        <img src="../seguridad.svg" alt="seguridad informatica inemediato villaneuva ingenieria"/>
                    </div>
                </div>
                <div>
                    <h1>Velocidad</h1>
                    <div className='circle'>
                        <img src="../reloj.svg" alt="velocidad inemediato villaneuva ingenieria"/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FourthIndex