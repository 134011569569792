import React , {useEffect} from 'react'

//Gsap
import {TimelineMax, gsap , CSSPlugin , Power4} from 'gsap/all'
gsap.registerPlugin(CSSPlugin)


const ThirdIndex =  () => {

    useEffect(() => {
        const trigger =  [...document.getElementsByClassName("serviceTarget")]

        var isIn =  false

        trigger.forEach.call(trigger, el=>{
            el.addEventListener('mouseover', e =>{
                const target =  el.getElementsByClassName('backService')[0]
                    const t2 = new TimelineMax()
                    .to(target, 0.1 , {top: "0%", ease: Power4.easeInOut})
            });
            el.addEventListener('touchstart', e =>{
                const target =  el.getElementsByClassName('backService')[0]
                    const t2 = new TimelineMax()
                    .to(target, 0.1 , {top: "0%", ease: Power4.easeInOut})
            });
        });

        trigger.forEach.call(trigger, el=>{
            el.addEventListener('mouseout', e =>{
                const target =  el.getElementsByClassName('backService')[0]
                    const t2 = new TimelineMax()
                    .to(target, 0.1 , {top: "70%", ease: Power4.easeInOut})
            });
            el.addEventListener('touchend', e =>{
                const target =  el.getElementsByClassName('backService')[0]
                    const t2 = new TimelineMax()
                    .to(target, 0.1 , {top: "70%", ease: Power4.easeInOut})
            });
        });
    }, [])

    return(
        <div className='thirdIndex'>
            <h1>SERVICIOS</h1>
            <div  className='serviceTarget' id='service1'>
                <div className="backService" id='backTextService1'>
                    <h1>EQUIPOS DE COMPUTO</h1>
                    <p>
                        Soluciones HelpDesk y On-Site para <strong>equipos de computo</strong> bajo los principales sistemas operativos.
                    </p>
                </div>
                <img src="../equipo-computo.png" alt="computadores pc gamer villanueva ingenieria "/>
            </div>
            <div  className='serviceTarget' id='service2'>
                <div className="backService" id="backTextService2">
                    <h1>SERVIDORES</h1>
                    <p>
                        Implementación, administración y configuración de <strong>servidores </strong>bajo la plataforma <strong>Windows Server </strong> (R) en todas las versiones.
                    </p>
                </div>
                <img src="../server.png" alt="servidores villanueva ingenieria "/>
            </div>
            <div  className='serviceTarget' id='service3'>
                <div className="backService" id='backTextService3'>
                    <h1>CÁMARAS DE SEGURIDAD</h1>
                    <p>
                        Diseño e implementación de sistemas de <strong>grabación digital D.V.R y Cámaras IP</strong>.
                    </p>
                </div>
                <img src="../camara-de-seguridad.png" alt="camaras de seguridad cámaras villanueva ingenieria "/>
            </div>
            <div className='serviceTarget' id='service4'>
                <div id='backTextService4' className="backService">
                    <h1>CONSULTORIA IT</h1>
                    <p>
                       <strong>Administración y soporte</strong> de centros de datos y departamentos de <strong>tecnologías de información.</strong>
                    </p>
                </div>
                <img src="../consultoria.jpg" alt="consultoria IT villanueva ingenieria "/>
            </div>
            <div  className='serviceTarget' id='service5'>
                <div id='backTextService5' className="backService">
                    <h1>REDES</h1>
                    <p>
                     Diseño e implementación de <strong>redes bajo protocolo IPV/4 e IPV/6, redes LAN-Ethernet, redes VPN Y Wireless</strong>
                    </p>
                </div>
                <img src="../redes.png" alt=" redes villanueva ingenieria "/>
            </div>
            <div  className='serviceTarget' id='service6'>
                <div id='backTextService6' className="backService">
                    <h1>EQUIPOS DE VOZ</h1>
                    <p>
                        Soporte en <strong>equipos de voz, hardware y software</strong>, equipos Panasonic.
                    </p>
                </div>
                <img src="../telefono.jpg" alt="equipos de voz telefonos villanueva ingenieria "/>
            </div>
        </div>
    )

}

export default ThirdIndex