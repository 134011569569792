import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {Link as Ancla , Element} from 'react-scroll';

//Sections
import FirstIndex from '../components/firstIndex'
import SecondIndex from '../components/secondIndex'
import ThirdIndex from '../components/thridIndex'
import FourthIndex from '../components/fourIndex'
import FivethIndex from '../components/fivethIndex'
import SixthIndex from '../components/sixthIndex'

const IndexPage = () => (
  <Layout Ancla={Ancla}>
    <SEO title="Villanueva Ingeniería -  Inicio" />
    <Element name='index'>
      <FirstIndex/>
    </Element>
    <Element name='about'>
      <SecondIndex/>
    </Element>
    <Element name='servicios'>
      <ThirdIndex/>
    </Element>
    <Element name='why'>
      <FourthIndex/>
    </Element>
    <Element name='proveedor'>
      <FivethIndex/>
    </Element>
    <Element name='contact'>
      <SixthIndex/>
    </Element>
  </Layout>
)

export default IndexPage
