import React from 'react'

const SecondIndex =  () => {

    return(
        <div className='secondIndex'>
            <img src="../switch.jpg" alt="Javier villanueva  villaneuva ingeniería IT colombia bogota sistemas"/>
            <h1 className='mainTitleSecond'>SOBRE NOSOTROS</h1>
            <p className='desc'>Somos una empresa creada con el objetivo de brindar asesoría 
                y soporte en las áreas de tecnologías de información y 
                comunicaciones desde el diseño hasta la implementación de 
                procesos.</p>
            <h1 className='misionTitle'>MISIÓN</h1>
            <p className='desc2'> Javier Villanueva S.A.S. es una empresa de servicios cuyo 
                objetivo principal es la asesoría y consultoría en tecnologías
                de información  y comunicaciones para mejorar  los procesos  
                de telecomunicaciones de sus clientes de una manera eficaz 
                y eficiente.</p>
            <h1 className='visionTitle'>VISIÓN</h1>
            <p className='desc3'> Javier Villanueva S.A.S. buscará ser una empresa líder en
                productos y servicios de tecnologías de información y 
                comunicaciones, reconocida por su responsabilidad profesional, 
                ética y social por sus clientes y por el gremio de su campo 
                en acción.</p>
        </div>
    )

}

export default SecondIndex