import React from 'react'

const FivethIndex =  () => {

    return(
        <>
        <div className='fivethIndex'>
            {/* <div className='back1Index'></div> */}
            {/* <img src="../procesador.png" alt="Javier villanueva  villaneuva ingeniería IT colombia bogota sistemas"/> */}
            <h1>DISTRIBUCIÓN</h1>
            <div className='distributionMain'>
                <div className='rowDistri'>
                    <div>
                        <img style={{width:"20vw"}} src="../proveedores/samsung.svg" alt="samsung villanueva ingenieria"/>
                    </div>
                    <div>
                        <img style={{width:"15vw", }} src="../proveedores/microsoft.svg" alt="microsoft villanueva ingenieria"/>
                    </div>
                    <div>
                        <img style={{width:"7vw", }} src="../proveedores/hp.svg" alt="hp villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/lg.svg" alt="lg villanueva ingenieria"/>
                    </div>
                </div>
                <div className='rowDistri'>
                    <div>
                        <img src="../proveedores/lenovo.svg" alt="lenovo villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/asus.svg" alt="asus villanueva ingenieria"/>
                    </div>
                    <div>
                        <img style={{width:"20vw", }} src="../proveedores/xiaomi.svg" alt="xiaomi villanueva ingenieria"/>
                    </div>
                    <div>
                        <img style={{width:"13vw", }} src="../proveedores/adata.svg" alt="adata villanueva ingenieria"/>
                    </div>
                </div>
                <div className='rowDistri'>
                    <div>
                        <img src="../proveedores/tp-link.svg" alt="tp-link villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/lexmark.svg" alt="lexmark villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/epson.svg" alt="epson villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/panda.svg" alt="panda villanueva ingenieria"/>
                    </div>
                </div>
                <div className='rowDistri'>
                    <div>
                        <img src="../proveedores/kaspersky.svg" alt="kaspersky villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/view-sonic.svg" alt="view-sonic villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/canon.svg" alt="canon villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/kingston.svg" alt="kingston villanueva ingenieria"/>
                    </div>
                </div>
            </div>
        </div>
        <div className='fivethIndex2'>
            {/* <div className='back1Index'></div> */}
            {/* <img src="../procesador.png" alt="Javier villanueva  villaneuva ingeniería IT colombia bogota sistemas"/> */}
            <h1>DISTRIBUCIÓN</h1>
            <div className='distributionMain'>
                <div className='rowDistri'>
                    <div>
                        <img style={{width:"20vw"}} src="../proveedores/samsung.svg" alt="samsung villanueva ingenieria"/>
                    </div>
                    <div>
                        <img style={{width:"15vw", }} src="../proveedores/microsoft.svg" alt="microsoft villanueva ingenieria"/>
                    </div>
                    <div>
                        <img style={{width:"7vw", }} src="../proveedores/hp.svg" alt="hp villanueva ingenieria"/>
                    </div>
                </div>
                <div className='rowDistri'>
                    <div>
                        <img src="../proveedores/lg.svg" alt="lg villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/lenovo.svg" alt="lenovo villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/asus.svg" alt="asus villanueva ingenieria"/>
                    </div>
                </div>
                <div className='rowDistri'>
                    <div>
                        <img style={{width:"20vw", }} src="../proveedores/xiaomi.svg" alt="xiaomi villanueva ingenieria"/>
                    </div>
                    <div>
                        <img style={{width:"13vw", }} src="../proveedores/adata.svg" alt="adata villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/tp-link.svg" alt="tp-link villanueva ingenieria"/>
                    </div>
                </div>
                <div className='rowDistri'>
                    <div>
                        <img src="../proveedores/lexmark.svg" alt="lexmark villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/epson.svg" alt="epson villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/panda.svg" alt="panda villanueva ingenieria"/>
                    </div>
                </div>
                <div className="rowDistri">
                    <div>
                        <img src="../proveedores/kaspersky.svg" alt="kaspersky villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/view-sonic.svg" alt="view-sonic villanueva ingenieria"/>
                    </div>
                    <div>
                        <img src="../proveedores/canon.svg" alt="canon villanueva ingenieria"/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}

export default FivethIndex